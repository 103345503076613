export const fallbackLng = 'en';
export const languages = [
  fallbackLng,
  'nl',
  'de',
  'fr',
  'it',
  'es',
  'sv',
  'nb',
  'da',
];
export const defaultNS = 'common';

export function getOptions(
  lng = fallbackLng,
  ns: string | string[] = defaultNS,
) {
  return {
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  };
}

'use client';

import { useUser } from '@/hooks/useUser';
import React, { createContext, useContext, useState } from 'react';
import { useTranslation as useT } from '../i18n/client';

const i18nContext = createContext<{
  t: any;
  lng: string;
  i18n: any;
}>({
  t: (key: string) => key,
  lng: 'en',
  i18n: {
    getFixedT: () => (key: string) => key,
  },
});

export default function LanguageProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user } = useUser();
  const [browserLang, setBrowserLang] = useState<string>('en');

  React.useEffect(() => {
    if (!navigator) return;
    setBrowserLang(navigator.language);
  }, []);

  const lng = user?.language?.toLowerCase() ?? browserLang;
  const { t, i18n } = useT(lng);
  return (
    <i18nContext.Provider value={{ t, lng, i18n }}>
      {children}
    </i18nContext.Provider>
  );
}

export const useTranslation = () => {
  const { t, lng, i18n } = useContext(i18nContext);
  return { t, lng, i18n };
};

import { create } from 'zustand';

interface StoreState {
  brand?: any;
  setBrand: (id: any) => void;

  user?: any;
  setUser: (user: any) => void;

  permissions?: any;
  setPermissions: (permissions: any) => void;

  edits?: {
    id?: string;
    [key: string]: any;
  };
  setEdits: (edits: any) => void;

  uploading?: boolean;
  setUploading: (uploading: boolean) => void;
}

export const useStore = create<StoreState>()((set) => ({
  brand: null,
  setBrand: (id) => set({ brand: id }),

  user: null,
  setUser: (user) => set({ user }),

  permissions: null,
  setPermissions: (permissions) => set({ permissions }),

  edits: undefined,
  setEdits: (edits) => set({ edits }),

  uploading: false,
  setUploading: (uploading) => set({ uploading }),
}));

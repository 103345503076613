import { Database, User } from 'backend';
import { useCallback, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useStore } from './useStore';
import { useSupabase } from './useSupabase';

export const useUser = () => {
  const supabase = useSupabase();
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);

  const permissions = {
    reports: ['SUPER', 'ADMIN', 'EXTERNAL'],
    'company.manage': ['SUPER', 'ADMIN'],
    'company.cpm': ['SUPER', 'ADMIN'],
    'company.adtag': ['SUPER', 'ADMIN'],
    'brand.create': ['SUPER', 'ADMIN'],
    'brand.typography': ['SUPER', 'ADMIN', 'EXTERNAL'],
    'covers.video': ['SUPER'],
    'stories.create': ['SUPER', 'ADMIN', 'EXTERNAL'],
    'stories.delete': ['SUPER', 'ADMIN', 'EXTERNAL'],
    'mode.slides': ['SUPER', 'ADMIN', 'EXTERNAL'],
    'mode.video': ['SUPER', 'ADMIN', 'EXTERNAL'],
    'mode.direct': ['SUPER', 'ADMIN', 'EXTERNAL'],
    super: ['SUPER'],
  };

  const {
    data: sessionUser,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useQuery(['user', 'me'], async () => {
    const result = await supabase.auth.getUser();
    return result?.data?.user;
  });

  const {
    data: memberData,
    isLoading,
    refetch: refetchMember,
  } = useQuery(
    ['members', 'me'],
    async () => {
      const result = await supabase
        .from('members')
        .select('*, company:companies ( * ) ')
        .eq('user_id', sessionUser?.id ?? '')
        .single();

      return result?.data;
    },
    { enabled: !!sessionUser },
  );

  const refetch = useCallback(() => {
    refetchUser();
    refetchMember();
  }, [refetchMember, refetchUser]);

  const getFullName = useCallback((data: User | null) => {
    const fname = `${data?.first_name ?? ''} ${data?.last_name ?? ''}`.trim();

    if (fname.length > 0) {
      return fname;
    }

    return null;
  }, []);

  const getPermissions = useCallback(
    (role: Database['public']['Enums']['role'] | null = 'EXTERNAL') => {
      const result = Object.keys(permissions)
        .map((key) =>
          permissions[key as keyof typeof permissions].includes(role as string)
            ? key
            : null,
        )
        .filter((n) => n);

      return result;
    },
    [],
  );

  useEffect(() => {
    if (!memberData) return;
    const permissions = getPermissions(memberData.role);
    const hasPermission = (key: keyof typeof permissions) =>
      permissions.includes(key as string);

    setUser({
      ...memberData,
      full_name: getFullName(memberData),
      permissions,
      hasPermission,
    });
  }, [memberData, getFullName, getPermissions, setUser]);

  return {
    // TODO: Return proper type for user
    user: {
      hasPermission: (key: keyof typeof permissions) => {
        return false;
      },
      ...user,
    },
    isLoading: isLoading || isLoadingUser,
    refetch,
  };
};

export const useUpdateUserMutation = () => {
  const supabase = useSupabase();
  const cache = useQueryClient();
  const queryFn = async ({
    id,
    data,
  }: {
    id: User['id'];
    data: Partial<User>;
  }) =>
    supabase
      .from('members')
      .update({ id, ...data })
      .eq('id', id)
      .throwOnError()
      .select('*')
      .single();

  const mutation = useMutation(queryFn, {
    onSuccess: (data: any) => {
      const queryKey = ['members', 'me'];
      cache.invalidateQueries(queryKey);
    },
  });

  return mutation;
};

import { createBrowserClient } from '@supabase/ssr';
import { Database } from 'backend';

let client: ReturnType<typeof createBrowserClient<Database>> | undefined;

export function createClient() {
  if (client) return client;

  try {
    return createBrowserClient<Database>(
      process.env.NEXT_PUBLIC_SUPABASE_URL!,
      process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
    );
  } catch (e) {
    console.error(e);
    return {} as any;
  }
}
